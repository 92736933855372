<template>
  <b-container fluid class="login-2022 mx-auto">
    <b-row class="vh-100" align-v="center">
      <b-col cols="4" class="l-left">
        <b-row class="flex-column h-100 ml-4 mr-5">
          <b-col cols="2" class="d-flex align-items-center">
            <div class="logo-black"> </div>
          </b-col>
          <b-col>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg="8" class="l-right">
        <b-row class="h-100 align-items-center" align-v="center">
          <div class="mx-auto login-action text-center">
            <div class="ee-logo mx-auto"> </div>
            <h1 class="my-3">Set Password</h1>
            <p class="text-center text-muted small p-0 m-0 pb-2 px-1">Minimum of 8 characters, 1 upper case, 1 lower case, 1 number.</p>
            <b-form @submit="initializeAccount" v-on:submit.prevent class="credentials-form" :class="{'larkin-form-dirty': $v.input.$dirty}">
              <div class="invalid-feedback d-block" v-if="formError()">
                {{formErrorMessage()}}
              </div>
              <b-modal id="modal-tos" title="Terms of Service" footer-class="d-none" body-class="info-modal">
                <div class="tos"><TermsOfService/></div>
              </b-modal>

              <b-form-group label-for="newPassword" class="my-2" :class="getInvalidFeedbackClass($v.input.newPassword.$invalid)">
                <b-input-group>
                  <b-form-input v-model="$v.input.newPassword.$model" type="password" placeholder="New Password" id="newPassword" @change="clearErrors()"></b-form-input>
                </b-input-group>
                <div class="invalid-feedback d-block" v-if="submitted && $v.input.newPassword.$invalid">
                  <span v-if="!$v.input.newPassword.required">New password is required</span>
                  <span v-else-if="!$v.input.newPassword.invalidNewPassword">New password not valid</span>
                  <span v-else-if="!$v.input.newPassword.sameAsCurrentPassword">New password cannot match current password</span>
                </div>
              </b-form-group>

              <b-form-group label-for="passwordConfirm" class="my-2" :class="getInvalidFeedbackClass($v.input.passwordConfirm.$invalid)">
                <b-input-group>
                  <b-form-input v-model="$v.input.passwordConfirm.$model" type="password" placeholder="Verify New Password" id="passwordConfirm" @change="clearErrors()"></b-form-input>
                </b-input-group>
                <div class="invalid-feedback d-block" v-if="submitted && $v.input.passwordConfirm.$invalid">
                  <span v-if="!$v.input.passwordConfirm.required">Confirm password is required</span>
                  <span v-else-if="!$v.input.passwordConfirm.notSameAsNewPassword">Must match new password</span>
                </div>
              </b-form-group>

              <b-form-group label-for="passwordConfirm" class="my-2" :class="getInvalidFeedbackClass($v.input.termsOfServiceAccepted.$invalid)">
                <b-form-group class="mb-1">
                  <div class="invalid-feedback d-block" v-if="submitted && $v.input.termsOfServiceAccepted.$invalid">
                    <span v-if="!$v.input.termsOfServiceAccepted.notAccepted">You must accept the terms of service to use this application</span>
                  </div>
                  <b-input-group class="pl-3 pt-1">
                    <b-form-checkbox v-model="$v.input.termsOfServiceAccepted.$model"></b-form-checkbox>
                    <label class="text-muted pt-1">I accept the <a style="text-decoration: underline; color: cornflowerblue;" @click="$bvModal.show('modal-tos')">Terms of Service</a></label>
                  </b-input-group>
                </b-form-group>
              </b-form-group>

              <b-btn :disabled="isSubmitting" type="submit" class="btn-larkin-yellow btn-full">
                <span class="login-text">Set Password</span>
                <div v-if="isSubmitting" v-bind:class="{'local-spinner-border':isSubmitting, 'local-spinner-spacer':!isSubmitting}"/>
              </b-btn>
            </b-form>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import TermsOfService from '@/components/TermsOfService.vue'
  import ApiProperties from '../../../src/main/resources/application'
  import FormMixin from '@/mixins/formMixin'
  import { required, sameAs } from 'vuelidate/lib/validators'

  export default {
    name: 'initializeAccount',
    components: {
      TermsOfService
    },
    mixins: [FormMixin],
    data: function() {
      return {
        isSubmitting: false,
        input: {
          newPassword: '',
          passwordConfirm: '',
          termsOfServiceAccepted: false
        }
      }
    },
    validations: {
      input: {
        newPassword: {
          required,
          invalidNewPassword(newPassword) {
            return this.$root.validation.password.regex.test(newPassword);
          }
        },
        passwordConfirm: {
          required,
          notSameAsNewPassword: sameAs(function() { return this.input.newPassword })
        },
        termsOfServiceAccepted: {
          notAccepted(val) { return val }
        }
      }
    },
    methods: {
      initializeAccount() {
        this.isSubmitting = !this.$v.$invalid

        this.submit('post', ApiProperties.api.updatePassword, {}, this.input, (response) => {
          this.$root.updateAuthStatus(response.data);
          this.$root.navigateToDefaultRoute();
          this.isSubmitting = false
        }, () => {
          this.isSubmitting = false
        })
      }
    }
  }
</script>

<style lang="scss">
  @import '../scss/login-pages';

  .legal {
    border: 1px solid #bac6d7;
    border-radius: 6px;
    height: 100px;
    margin: 0rem 0rem 1rem 0rem;
    max-width: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0rem 0.5rem 0rem 0.5rem;
    text-align: left;
    white-space: pre-wrap;
    pre {
      font-size: .8em;
    }
  }

  .accept-terms-label {
    padding-top: 2px;
    font-size: .8em;
    a, a:visited, a:hover {
      font-size: 1em !important;
    }
  }

  .custom-checkbox {
    padding-left: 0;
    width:25px;
  }

  .info-modal {
    &.modal-body {
      p {
        a {
          color: #66bbe6;
          text-decoration: underline;
        }
      }
    }
  }

  .tos {
    width: 100%;
    height: 400px;
    overflow: auto;
  }


</style>
